<template>
  <v-navigation-drawer v-model="drawer" absolute temporary>
    <div class="drawer flex-1 d-flex flex-column">
      <!-- ------------ Avatar ------------ -->
      <v-list-item v-if="parent">
        <v-list-item-avatar>
          <v-img :src="parent.profile.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ parent.profile.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- ------------ Menu ------------ -->
      <div v-for="item in menu" :key="item.id">
        <v-list-item link v-if="(parent && item.authenticated) || (!parent && !item.authenticated)" @click="go(item.route)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <!-- ------------ Divider ------------ -->
      <v-divider></v-divider>
      <!-- ------------ Actions (authenticated) ------------ -->
      <v-list-item link v-if="parent" @click="$emit('logout')">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- ------------ Actions (Unauthenticated) ------------ -->
      <div v-else>
        <v-list-item link @click="go('Login')">
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="go('Register')">
          <v-list-item-content>
            <v-list-item-title>Register</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'NavigationDrawer',
  props: ['parent', 'menu', 'globalDrawer'],
  data: () => ({
    drawer: false,
  }),
  created() {
    this.drawer = this.globalDrawer
  },
  watch: {
    globalDrawer() {
      if (this.globalDrawer) this.drawer = true
    },
    drawer() {
      if (this.drawer == false) this.$emit('close')
    }
  }
}
</script>