import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

var firebaseConfig = {}

if (window.location.hostname == process.env.VUE_APP_PRODUCTION_HOSTNAME) {
  firebaseConfig = {
    apiKey: "AIzaSyBdsvSF67KpxN9YluzHrp37Wg_pPA57D9M",
    authDomain: "kaizen-kids-app.firebaseapp.com",
    projectId: "kaizen-kids-app",
    storageBucket: "kaizen-kids-app.appspot.com",
    messagingSenderId: "171360982447",
    appId: "1:171360982447:web:28b12b5c95a775efc1cb5a",
    measurementId: "G-2GDFPCHXKV"
  };
  console.log('loaded production config')
} else {
  firebaseConfig = {
    apiKey: "AIzaSyD4wdaBbLLQId2_KAidvI65M4WjjSF_nqk",
    authDomain: "kaizen-kids-development.firebaseapp.com",
    projectId: "kaizen-kids-development",
    storageBucket: "kaizen-kids-development.appspot.com",
    messagingSenderId: "20184124775",
    appId: "1:20184124775:web:91afeef7a08df032f8b3c2",
    measurementId: "G-2W6XPCHNPT"
  };
  console.log('loaded development config')
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);