<template>
  <v-container>
    <v-card>
      <v-card-title>Login</v-card-title>
      <v-card-text v-if="authenticationType==''">
        <v-text-field 
          v-model="email"
          label="E-mail"
          :disabled="authenticating"
          :validate-on-blur="true"
          outlined
          hide-details>
        </v-text-field>
        <v-btn block class="mt-3" color="primary" @click="authenticationType='email'" :disabled="!email"><v-icon left>mdi-email</v-icon>Continue with Email</v-btn>
        <div class="d-flex flex-row justify-center my-3 login-divider">
          <div class="px-3 overline login-divider-or">or</div>
        </div>
        <v-btn block outlined color="red" class="darken-1 mt-3" dark @click="googleAuth()"><v-icon left>mdi-google</v-icon>Continue with Google</v-btn>
        <div class="caption mt-3">Trouble logging in? <a @click="go('Recover')">Recover your account</a></div>
        <div class="caption">Don't have an account? <a @click="go('Register')">Register</a></div>
      </v-card-text>
      <!-- <v-card-text v-if="authenticationType==''">
        <v-btn block outlined color="grey" class="darken-4 mt-3" dark @click="authenticationType='email'"><v-icon left>mdi-email</v-icon>Continue with Email</v-btn>
        <v-btn block outlined color="red" class="darken-1 mt-3" dark @click="googleAuth()"><v-icon left>mdi-google</v-icon>Continue with Google</v-btn>
        <div class="caption mt-3">Don't have an account? <a @click="go('Register')">Register</a></div>
      </v-card-text> -->
      <v-card-text v-if="authenticationType=='email'">
        <v-alert type="error" v-if="authFeedback">{{ authFeedback }}</v-alert>
        <v-form ref="loginForm" v-model="validLoginForm">
          <v-text-field 
            v-if="authFeedback"
            v-model="email"
            label="E-mail"
            :rules="emailRules"
            :disabled="authenticating"
            :validate-on-blur="true"
            outlined>
          </v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :disabled="authenticating"
            :rules="passwordRules"
            :type="showPassword ? 'text' : 'password'"
            :validate-on-blur="true"
            label="Password"
            @click:append="showPassword = !showPassword"
            outlined>
          </v-text-field>
          <v-btn @click="login()" class="mt-1" color="primary" block :loading="authenticating" :disabled="!email || !password">Login</v-btn>
          <div class="caption mt-3">Trouble logging in? <a @click="go('Recover')">Recover your account</a></div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth'
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      validLoginForm: null,
      authenticating: false,
      authenticationType: '',
      authFeedback: '',
      showPassword: false,
      passwordRules: [
        value => !!value || 'Password is required.',
        value => value && value.length >= 8 || 'Min 8 characters',
        value => value && value.toLowerCase() !== value || 'Must contain one uppercase character',
        value => value && value.toUpperCase() !== value || 'Must contain one lowercase character',
        value => value && /^[a-zA-Z0-9]+$/.test(value) === false || 'Must contain a complex character (eg. !@#$%^&*?)',
      ],
      emailRules: [
        value => !!value || 'Email is required.',
        value => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Please enter a valid email address'
      ],
    }
  },
  methods: {
    login() {
      if (!this.validLoginForm) return false
      this.authenticating = true
      this.authFeedback = ''
      const auth = getAuth()
      signInWithEmailAndPassword(auth, this.email, this.password)
      .then((credentials) => {
        // Signed in
        this.success = 'Login Successful'
        this.go('Dashboard')
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/missing-email":
            this.authFeedback = 'Please enter an email'; break;
          case "auth/missing-password":
            this.authFeedback = 'Please enter a password'; break;
          case "auth/invalid-email":
           this.authFeedback = 'Please enter a valid email'; break;
          case "auth/user-not-found":
            this.authFeedback = 'Invalid credentials'; break;
          case "auth/wrong-password":
            this.authFeedback = 'Invalid credentials'; break;
          default:
            this.authFeedback = error.code
        }
        this.authenticating = false
      })
    },
    googleAuth() {
      this.overlay = true
      this.authFeedback = ''
      let provider = new GoogleAuthProvider()
      let auth = getAuth()
      provider.addScope('email')
      provider.addScope('profile')
      // provider.addScope('https://www.googleapis.com/auth/user.birthday.read')
      // provider.addScope('https://www.googleapis.com/auth/user.gender.read')
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      
      let loginMethod = location.hostname === 'localhost' ? signInWithPopup(auth, provider) : signInWithRedirect(auth, provider)
      loginMethod.then( credentials => {
        this.go('Dashboard')
      }).catch( error => {
        if (error.code == 'auth/popup-closed-by-user') {
          this.authFeedback = 'Google authentication popup closed.'
        } else {
          this.authFeedback = error.message
        }
        this.overlay = false
      })
    }
  }
}
</script>
<style lang="scss">
  .login-divider {
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1rem;
    background: #8c9196;
    background: linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 1px), #8c9196 calc(50%), rgba(0,0,0,0) calc(50% + 1px));
    .login-divider-or {
      background: white;
    }
  }
</style>