<template>
  <v-container>
    <v-card>
      <v-card-title>Account Recovery</v-card-title>
      <v-card-text v-if="success">
        <v-alert type="success">A recovery email has been sent. Please follow the instructions provided to continue.</v-alert>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="error" v-if="recoveryFeedback">{{ recoveryFeedback }}</v-alert>
        <v-text-field 
          v-model="email"
          label="E-mail"
          :disabled="recovering"
          outlined
          hide-details>
        </v-text-field>
        <v-btn block :disabled="!email" :loading="recovering" color="primary" @click="sendRecoveryEmail()" class="mt-3">Send Recovery Email</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { getAuth, sendPasswordResetEmail, signInWithPopup, signInWithRedirect, getAdditionalUserInfo, GoogleAuthProvider } from 'firebase/auth'
export default {
  name: 'Recover',
  data: () => ({
    email: '',
    success: '',
    recoveryFeedback: '',
    recovering: false,
  }),
  methods: {
    sendRecoveryEmail() {
      this.recovering = true
      this.recoveryFeedback = ''
      const auth = getAuth()
      sendPasswordResetEmail(auth, this.email).then(() => {
        // Password reset email sent successfully
        this.success = true
      }).catch((error) => {
        // Error sending password reset email
        switch (error.code) {
          case "auth/invalid-email":
           this.recoveryFeedback = 'Please enter a valid email'; break;
          default:
            this.recoveryFeedback = error.code
        }
        this.authenticating = false
        this.recovering = false
      });
    }
  }
}
</script>