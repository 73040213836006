<template>
  <v-container v-if="deck">
    <div>
      <v-card-title>
        <v-icon left color="primary">mdi-cards</v-icon>Card Master&trade;
      </v-card-title>
    </div>
    <div v-if="!training">
      <div class="text-overline">Mastery</div>
      <div class="d-flex flex-column flex-sm-row">
        <div id="table">
          <div class="d-flex flex-row">
            <div v-for="c in 13" :key="'table-'+ c" class="header box">
              <div v-if="c > 1">{{ c-1 }}</div>
            </div>
          </div>
          <div>
            <div v-for="b in 12" :key="'table-'+ b" class="d-flex flex-row">
              <div class="header box">{{ b }}</div>
              <div v-for="a in 12" :key="'table-'+ b + '-' + a">
                <div v-if="getLevel(deck[(a-1)+((b-1)*12)]) < 5" class="box" :class="'level-' + getLevel(deck[(a-1)+((b-1)*12)])" @click="highlight = (a-1)+((b-1)*12)">
                  <!-- {{ (a-1)+((b-1)*12) }} -->
                  {{ deck[(a-1)+((b-1)*12)].product }}
                </div>
                <div v-else class="box level-5">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="highlight" id="highlight" class="ml-6 d-flex flex-column">
          <v-card class="flash-card mb-4">
            <div class="d-flex flex-row width-100 mr-3">
              <v-spacer></v-spacer>
              <v-icon v-for="i in getLevel(deck[highlight])" :key="'star'+i">mdi-star-outline</v-icon>
            </div>
            <div class="text-h1 pa-2">{{ deck[highlight].a }}</div>
            <div class="text-h1 pa-2">{{ deck[highlight].b }}</div>
            <div class="divide ma-2"></div>
            <div class="text-h1 answer pa-2">{{ deck[highlight].product }}</div>
          </v-card>
          <!-- Markers -->
          <div>
            <template v-for="index in 10">
              <!-- deck[highlight].history.slice(-10)[index-1] -->
              <v-btn small icon :key="index" :color="getAttemptColour(deck[highlight].history.slice(-10)[index-1])">
                <v-icon small>mdi-circle</v-icon>
              </v-btn>
            </template>
          </div>
          <!-- Stats -->
          <div class="d-flex flex-row">
            <div class="flex-1">
              <div class="text-overline">Last 10</div>
              <div class="subtitle-2">Guesses: {{ deck[highlight].history.slice(-10).length }}</div>
              <div class="subtitle-2">Accuracy: {{ getAccuracy(deck[highlight].history.slice(-10)) }}%</div>
              <div class="subtitle-2">Avg. Speed: {{ getSpeed(deck[highlight].history.slice(-10)) }}s</div>
            </div>
            <div class="flex-1">
              <div class="text-overline">History</div>
              <div class="subtitle-2">Guesses: {{ deck[highlight].attempts }}</div>
              <div class="subtitle-2">Accuracy: {{ getAccuracy(deck[highlight].history) }}%</div>
              <div class="subtitle-2">Avg. Speed: {{ getSpeed(deck[highlight].history) }}s</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-overline">Deck</div>
      <div class="deck d-flex flex-row flex-wrap mx-2">
        <template v-for="i in deck.length">
          <v-card v-if="getLevel(deck[i-1]) < 5" :key="'deck' + i" class="mini-flash-card d-flex flex-column" :class="'level-' + getLevel(deck[i-1])">
            <div class="text-right">{{ deck[i-1].a }}</div>
            <div class="text-right">x{{ deck[i-1].b }}</div>
          </v-card>
        </template>
      </div>
      <div class="text-overline">Study Pile</div>
      <div class="mb-3">
        <v-btn-toggle v-model="filter" dense color="primary" multiple @change="updatePile()">
          <v-btn v-for="i in 12" :key="'filter'+i" elevation="2">
            {{ i }}
          </v-btn>
        </v-btn-toggle>
        <v-btn @click="squares()" class="ml-2">Squares</v-btn>
        <v-btn @click="review()" class="ml-2">Review</v-btn>
        <v-btn @click="train()" :disabled="pile.length==0" :dark="pile.length > 0" color="primary" class="ml-2"><v-icon left>mdi-arm-flex</v-icon>Train</v-btn>
      </div>
    </div>
    <div v-else class="d-flex flex-column flex-sm-row">
      <div class="flex-1 d-flex content-center flex-column align-center">
        <v-card class="flash-card mb-4">
          <div class="d-flex flex-row width-100 mr-3">
            <v-spacer></v-spacer>
            <v-icon v-for="i in getLevel(pile[card])" :key="'star'+i">mdi-star-outline</v-icon>
          </div>
          <div class="text-h1 pa-2">{{ pile[card].a }}</div>
          <div class="text-h1 pa-2">{{ pile[card].b }}</div>
          <div class="divide ma-2"></div>
          <div class="text-h1 answer pa-2">{{ guess }}</div>
        </v-card>
        <div class="d-flex flex-row mb-4">
          <!-- Markers -->
          <template v-for="index in 10">
            <v-btn small icon :key="index" :color="getAttemptColour(pile[card].history.slice(-10)[index-1])">
              <v-icon small>mdi-circle</v-icon>
            </v-btn>
          </template>
        </div>
        <v-alert v-if="master" class="flash-alert mb-3" color="yellow">{{ master }}</v-alert>
        <v-alert v-if="success && !master" class="flash-alert mb-3" type="success">{{ success }}</v-alert>
        <v-alert v-if="fail" class="flash-alert mb-3" type="error">{{ fail }}</v-alert>
      </div>
      <div class="flex-1 d-flex justify-center">
        <div class="keypad d-flex flex-column">
          <div class="d-flex flex-row mb-2">
            <v-btn x-large class="mr-2" @click="guess+='7'">7</v-btn>
            <v-btn x-large class="mr-2" @click="guess+='8'">8</v-btn>
            <v-btn x-large class="mr-2" @click="guess+='9'">9</v-btn>
          </div>
          <div class="d-flex flex-row mb-2">
            <v-btn x-large class="mr-2" @click="guess+='4'">4</v-btn>
            <v-btn x-large class="mr-2" @click="guess+='5'">5</v-btn>
            <v-btn x-large class="mr-2" @click="guess+='6'">6</v-btn>
          </div>
          <div class="d-flex flex-row mb-2">
            <v-btn x-large class="mr-2" @click="guess+='1'">1</v-btn>
            <v-btn x-large class="mr-2" @click="guess+='2'">2</v-btn>
            <v-btn x-large class="mr-2" @click="guess+='3'">3</v-btn>
          </div>
          <div class="d-flex flex-row mb-2">
            <v-btn x-large class="mr-2" @click="guess=''"><v-icon>mdi-backspace-outline</v-icon></v-btn>
            <v-btn x-large class="mr-2" @click="guess+='0'">0</v-btn>
            <v-btn x-large class="mr-2" color="primary" @click="check()">Go</v-btn>
          </div>
          <!-- <v-text-field v-model="guess" autofocus hide-details @keydown.enter="check()"></v-text-field> -->
          <v-btn @click="stop()" text class="mt-3">Stop</v-btn>
        </div>
      </div>
    </div>
    <div class="pile d-flex flex-row flex-wrap pa-2">
      <v-card v-for="i in pile.length" :key="'pile' + i" class="mini-flash-card d-flex flex-column">
        <div class="text-right">{{ pile[i-1].a }}</div>
        <div class="text-right">x{{ pile[i-1].b }}</div>
      </v-card>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'CardMaster',
  props: ['parent'],
  data: () => ({
    deck: [],
    pile: [],
    filter: [],
    training: false,
    guess: '',
    card: null,
    time: 0,
    success: '',
    fail: '',
    master: '',
    highlight: null
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.parent) return
      if (this.parent.cards == undefined) {
        this.create()
        this.parent.cards = JSON.stringify(this.deck)
        this.$emit('save')
        console.log('table created')
      } else {
        this.deck = JSON.parse(this.parent.cards)
        console.log('table loaded')
      }
    },
    create() {
      let deck = []
      for( let b=1; b<=12; b++) {
        for( let a=1; a<=12; a++) {
          deck.push({
            a: a,
            b: b,
            product: a*b,
            correct: 0,
            attempts: 0,
            time: 0,
            level: 0,
            history: []
          })
        }
      }
      this.deck = deck
    },
    updatePile() {
      let fullPile = this.deck.filter(card => (this.filter.indexOf(card.a-1) > -1 || this.filter.indexOf(card.b-1) > -1))
      this.pile = fullPile.filter(card => this.getLevel(card) < 5)
    },
    train() {
      this.training = true
      this.flash()
    },
    stop() {
      this.training = false
      this.success = ''
      this.fail = ''
      this.master = ''
      this.filter = ''
      this.parent.cards = JSON.stringify(this.deck)
      this.$emit('save') 
    },
    flash() {
      // Select random card from the pile
      // current time in milliseconds
      this.time = Date.now()
      this.guess = ''
      let index = Math.floor(Math.random() * this.pile.length)
      this.card = index
      this.guess = ''
    },
    check() {
      this.fail = ''
      this.success = ''
      this.master = ''
      if (this.guess == this.pile[this.card].product) {
        this.pile[this.card].correct++
        this.success = JSON.stringify('Correct! ' + this.pile[this.card].a + ' x ' + this.pile[this.card].b + ' = ' + this.pile[this.card].product)
      } else {
        this.fail = JSON.stringify('OOPS! ' + this.pile[this.card].a + ' x ' + this.pile[this.card].b + ' = ' + this.pile[this.card].product)
      }
      this.pile[this.card].attempts++
      this.pile[this.card].time += Date.now() - this.time
      this.pile[this.card].history.push({
        guess: this.guess,
        time: Date.now() - this.time,
        correct: this.guess == this.pile[this.card].product
      })
      this.guess = ''
      // ------------ Level Up? ------------ 
      let computedLevel = this.getLevel(this.pile[this.card])
      if (computedLevel > this.pile[this.card].level || computedLevel == 5) {
        this.pile[this.card].level = computedLevel
        this.master = 'Way to go! You know that one!'
        this.pile.splice(this.card, 1)
      }
      if (this.pile.length > 0) {
        this.flash()
      } else {
        this.stop()
      }
    },
    getAccuracy(history) {
      if (history == undefined) return undefined
      if (history.length == 0) return 0
      let correct = 0
      for (let i = 0; i < history.length; i++) {
        if (history[i].correct) correct++
      }
      return Math.round(correct/history.length*100)
    },
    getSpeed(history) {
      if (history == undefined) return undefined
      if (history.length == 0) return 0
      let time = 0
      for (let i = 0; i < history.length; i++) {
        time += history[i].time
      }
      return Math.round(time/history.length/1000)
    },
    getCardColour(card) {

    },
    getAttemptColour(attempt) {
      if (attempt == undefined) return 'grey lighten-3'
      if (!attempt.correct) return 'red'
      if (attempt.time <= 3000) return 'purple'
      if (attempt.time <= 6000) return 'green'
      return 'green lighten-3'
    },
    getLevel(card) {
      let level = 0
      // Level 0: No history
      if (card == undefined) return level
      if (card.history.length == 0) return level
      // Level 1: Last answer was correct
      if (card.history.slice(-1)[0].correct) level = 1
      // Level 2: Last 5 answers were correct
      if (card.history.slice(-5).length == 5)
        if (this.getAccuracy(card.history.slice(-5)) == 100)
          level = 2
      // Level 3: Last 10 answers were correct
      if (card.history.slice(-10).length == 10)
        if (this.getAccuracy(card.history.slice(-10)) == 100)
          level = 3
      // Level 4: Last 10 answers were correct in less than 60 seconds
      if (card.history.slice(-10).length == 10 && this.getAccuracy(card.history.slice(-10)) == 100) {
        if (this.getSpeed(card.history.slice(-10)) <= 60)
          level = 4
        // Level 5: Last 10 answers were correct in less than 30 seconds
        if (this.getSpeed(card.history.slice(-10)) <= 30)
          level = 5
      }
      return level
    },
    squares() {
      this.filter = []
      let unfilteredPile = []
      for( let i = 0; i < 12; i++) {
        unfilteredPile.push(this.deck[12*i+i])
      }
      this.pile = unfilteredPile.filter(card => this.getLevel(card) < 5)
      // this.pile = unfilteredPile
    },
    review() {
      this.filter = [0,1,2,3,4,5,6,7,8,9,10,11]
      this.updatePile()
    }
  },
  watch: {
    parent() {
      this.init()
    },
    guess() {
      if (this.guess.toString().length == this.pile[this.card].product.toString().length)
        this.check()
    }
  }
}
</script>
<style scoped lang="scss">
  .mini-flash-card {
    width: 30px;
    height: 40px;
    margin-left: -10px;
    margin-bottom: 5px;
    color: grey;
    font-size: 8px;
    display: flex;
    align-items: right;
    justify-content: center;
    padding-right: 8px;
  }
  .level-1 {
    background: #f6fff6 !important;
  }
  .level-2 {
    background: #e2ffe2 !important;
  }
  .level-3 {
    background: #c4ffc4 !important;
  }
  .level-4 {
    background: #69ff69 !important;
  }
  .level-5 {
    background: yellow !important;
  }
  .box.level-5 {
    background: none !important;
  }
  .flash-card {
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .divide {
      width: 75%;
      border: 2px solid black
    }
    .answer {
      color: lightskyblue;
      min-height: 112px;
      height: 112px;
    }
  }
  .flash-alert {
    width: 300px;
    margin: 0;
  }
  .box {
    width: 40px;
    height: 40px;
    background: white;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header.box {
    background: #d32f2f;
    color: white;
  }
</style>