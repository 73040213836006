<template>
  <div>
    <v-btn block rounded dark color="accent" @click="step = 1">Start Journey</v-btn>
    <v-dialog v-model="step" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-sheet>
        <v-toolbar dark flat color="accent">
          <v-btn icon dark @click="step--">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>Start Your Journey</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="step=0" color="red lighten-2">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-sheet>
      <v-sheet color="white" class="flex-1">
        <v-slide-x-transition>
          <div v-if="step==1">
            <v-subheader>Grown Up Needed</v-subheader>
            <div class="d-flex flex-column align-center">
              <v-img src="@/assets/images/tiger4.jpeg" contain max-width="300"></v-img>
              <h1>Grown Up Needed!</h1>
              <p>Pass the device to your Mom or Dad so they can get you setup</p>
              <v-btn rounded dark color="accent" class="mx-3" @click="step = 2">I'm a Grown Up</v-btn>
              <div class="caption grey--text mt-2">You'll need access to email and a phone</div>
            </div>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-if="step==2" v-show="step==2">
            <v-subheader>Create Account</v-subheader>
            <div class="d-flex flex-column align-center">
              <v-btn rounded dark color="accent" class="mx-3" @click="step = 3">Login with Google</v-btn>
            </div>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-if="step==3" v-show="step==3">
            <v-subheader>Phone Number Verification</v-subheader>
            <div class="d-flex flex-column align-center">
              <v-btn rounded dark color="accent" class="mx-3" @click="step = 4">Verify Phone Number</v-btn>
            </div>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-if="step==4" v-show="step==4">
            <v-subheader>Add Child</v-subheader>
            <div class="d-flex flex-column align-center">
              <v-btn rounded dark color="accent" class="mx-3" @click="step = 5">Add Child</v-btn>
            </div>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-if="step==5">
            <v-subheader>Summary</v-subheader>
            <div class="d-flex flex-column align-center">
              <v-btn rounded dark color="accent" class="mx-3" @click="step = 6">Looks Good!</v-btn>
            </div>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-if="step==6">
            <v-subheader>Payment</v-subheader>
            <div class="d-flex flex-column align-center">
              <v-btn rounded dark color="accent" class="mx-3">Pay</v-btn>
            </div>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-if="step==7">
            <v-subheader>Success</v-subheader>
            <div class="d-flex flex-column align-center">

            </div>
          </div>
        </v-slide-x-transition>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>
export default {
    name: 'Start',
    data: () => ({
      step: 0,
    }),
    methods: {
    }
}
</script>
<style>
  .v-dialog.v-dialog--active.v-dialog--fullscreen {
    display: flex !important;
    flex-direction: column !important;
  }
</style>