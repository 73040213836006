<template>
  <div>
    <v-app-bar color="primary" class="flex-none" flat dark>
      <v-app-bar-nav-icon class="d-flex d-sm-none" @click="$emit('drawer')"></v-app-bar-nav-icon>
      <v-app-bar-title>{{ appName }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <div v-if="parent" class="d-flex flex-row align-center">
        <!-- ------------ Mobile ------------ -->
        <v-btn icon class="d-flex d-sm-none" @click="$emit('dashboard')" ><v-icon color="primary" class="text--darken-2">mdi-account</v-icon></v-btn>
        <v-btn icon class="d-flex d-sm-none" @click="$emit('logout')"><v-icon>mdi-logout</v-icon></v-btn>
        <!-- ------------ Desktop ------------ -->
        <div class="mr-2 primary--text text--darken-2 d-none d-sm-flex">{{ parent.profile.email }}</div>
        <v-btn @click="$emit('logout')" text class="d-none d-sm-flex">Logout</v-btn>
      </div>
      <div v-else class="d-flex flex-row align-center">
        <v-btn @click="go('Login')" class="mr-1 d-none d-sm-flex" text>Login</v-btn>
        <v-btn @click="go('Register')" class="d-none d-sm-flex" text>Register</v-btn>
      </div>
    </v-app-bar>
    <v-sheet color="grey" class="lighten-2 d-none d-sm-flex">
      <v-tabs v-model="menuIndex">
        <div v-for="item in menu" :key="item.id" class="d-flex flex-row">
          <v-tab v-if="(parent && item.authenticated) || (!parent && !item.authenticated)" @click="go(item.route)">
            <span>{{ item.title }}</span>
          </v-tab>
        </div>
      </v-tabs>
    </v-sheet>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: ['parent','menu'],
  data: () => ({
    menuIndex: 0,
    appName: 'Kaizen Kids'
  }),
}
</script>