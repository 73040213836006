<template>
  <div class="d-flex flex-column align-center justify-center flex-1">
    <h1 class="japanese-text">改善キッズ</h1>
    <h1>Kaizen Kids&trade;</h1>
    <!-- Parent: {{  parent }}<br>
    Kid: {{  kid }} -->
    <v-container class="align-center justify-center max-600">
      <Start v-if="!parent"></Start>
      <v-btn class="mt-3" block rounded @click="continueJourney()">Continue Journey</v-btn>
    </v-container>
    <!-- <Continue></Continue> -->
  </div>
</template>

<script>
  import Start from '@/components/Start'
  import Continue from '@/components/Continue'
  export default {
    name: 'Landing',
    props: ['parent', 'kid'],
    components: {
    Start,
    Continue,
},
    data: () => ({
      
    }),
    methods: {

      continueJourney() {
        console.log('continue')
        // this.$router.push({ name: 'Parent' })
      }
    }
  }
</script>

<style>
  .max-600 {
    max-width: 600px;
  }
</style>