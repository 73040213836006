<template>
  <v-container>
    <v-card>
      <v-card-title>Socrates</v-card-title>
      <v-card-text v-if="parent && parent.conversation">
        <div v-for="(message,index) in parent.conversation" :key="index">
          <div v-if="message.role == 'user'" class="d-flex mb-2 flex-row-reverse">
            <v-avatar size="40" class="ml-3"><img :src="parent.profile.avatar"></v-avatar>
            <v-sheet color="primary" dark class="pa-2" rounded elevation="1">{{ message.content }}</v-sheet>
          </div>
          <div v-else-if="message.role != 'system'" class="d-flex mb-2 flex-row">
            <v-avatar size="40" class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/kaizen-kids-app.appspot.com/o/resources%2Fimages%2Fsocrates-300.png?alt=media&token=4a06793d-2f90-4c52-b898-4f8f94e8156e"></v-avatar>
            <v-sheet rounded elevation="1" class="pa-2" color="grey lighten-3">{{ message.content }}</v-sheet>
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <div class="text-caption">If you could say anything to Socrates what would it be?</div>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="message"
          label="Message"
          outlined
          hide-details
          :disabled="thinking"
          append-outer-icon="mdi-send" @click:append-outer="sendMessage()"
          @keyup.enter="sendMessage()"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Socrates',
  props: ['parent'],
  data() {
    return {
      message: "",
      thinking: false,

    }
  },
  created() {
  },
  methods: {
    sendMessage() {
      console.log({
          user: this.parent.uid,
          inquiry: this.message
        })
      if (this.message == "") return
      this.thinking = true
      axios({
        method: 'post',
        url: 'https://us-central1-kaizen-kids-development.cloudfunctions.net/socrates',
        data: {
          user: this.parent.uid,
          inquiry: this.message
        }
      }).then((response) => {
        this.message = ""
        this.thinking = false
      }).catch((error) => {
        this.thinking = false
      })
    }
  }
}
</script>