<template>
  <v-container>
    <div>
      <v-card-title>
        <v-icon left color="primary">mdi-cards</v-icon>Card Master&trade;
      </v-card-title>
    </div>
    <div class="text-overline">Deck</div>
    <div class="deck d-flex flex-row flex-wrap">
      <template v-for="i in deck.length">
        <v-card :key="'deck' + i" class="mini-flash-card">{{ i }}</v-card>
      </template>
    </div>
    <div class="text-overline">Study Pile</div>
    <div class="pile d-flex flex-row flex-wrap">
      <v-card v-for="i in pile.length" :key="'pile' + i" class="mini-flash-card"></v-card>
    </div>

    {{ deck }}








    <div class="d-flex flex-column flex-sm-row">
      <div class="d-flex flex-1 flex-wrap">
        <!-- deck: {{ deck }}<br> -->
        <!-- pile: {{ pile }}<br> -->
        
      </div>
      <div class="d-flex flex-2">
        1
      </div>
    </div>



    <v-row>
      <v-col v-if="!training" cols="6">
        <v-card-title>
          Summary
        </v-card-title>
        <v-card-text>









          <div v-if="table" class="d-flex flex-column table">
            <div v-for="b in 13" :key="b" class="d-flex flex-row math-row">
              <div v-for="a in 13" :key="a" class="d-flex">
                <div v-if="a == 1 && b-1 == 0" class="box header"></div>
                <div v-else-if="a == 1" class="box header">{{ b-1 }}</div>
                <div v-else-if="b == 1" class="box header">{{ a-1 }}</div>
                <div v-else class="box" :class="table[((a-1)+((b-2)*12))-1].correct > 10 + (10*(table[((a-1)+((b-2)*12))-1].attempts - table[((a-1)+((b-2)*12))-1].correct)) ? 'known' : ''">
                  <div v-if="table[((a-1)+((b-2)*12))-1].correct > 10 + (10*(table[((a-1)+((b-2)*12))-1].attempts - table[((a-1)+((b-2)*12))-1].correct))" class="text-caption"></div>
                  <div v-else>
                    <!-- index -->
                    <!-- {{ table[((a-1)+((b-2)*12))-1].answer }} -->
                    <!-- {{ (table[((a-1)+((b-2)*12))-1].correct/table[((a-1)+((b-2)*12))-1].attempts).toFixed(1) }} -->
                    <!-- {{ ((table[((a-1)+((b-2)*12))-1].correct/table[((a-1)+((b-2)*12))-1].attempts)/speed).toFixed(1) }} -->
                    <!-- {{ table[((a-1)+((b-2)*12))-1].speed / ((table[((a-1)+((b-2)*12))-1].correct/table[((a-1)+((b-2)*12))-1].attempts)) }} -->
                    {{ (table[((a-1)+((b-2)*12))-1].correct) }}
                    <!-- {{ table[((a-1)+((b-2)*12))-1].speed }} -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-col>
      <v-col v-else cols="6">

      </v-col>
      <v-col cols="6">
        <v-card-title>
          Training Arena
        </v-card-title>
        <v-card v-if="!training">
          <v-card-text>
            <div class="d-flex flex-row">
              <v-btn icon v-for="i in 12" :key="i" @click="focus=i; train()">{{ i }}</v-btn>
            </div>
            <v-text-field v-model="focus" label="Focus Number"></v-text-field>
            <div class="d-flex flex-row">
              <v-btn @click="train()"><v-icon left>mdi-arm-flex</v-icon>Train</v-btn>
              <v-spacer></v-spacer>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-else class="pa-5">
          <div class="text-h1 text-center">{{ card.a }}</div>
          <div class="text-h1 text-center">{{ card.b }}</div>
          <v-divider></v-divider>
          <v-text-field v-model="card.guess" autofocus @keyup.enter="check()"></v-text-field>
        </v-card>
        <v-btn v-if="training" class="mt-5" @click="stop()">Stop</v-btn>
        <v-btn v-else text @click="reset()"><v-icon left>mdi-refresh</v-icon>Reset</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'Math',
  props: ['parent'],
  data: () => ({
    table: null,
    training: false,
    timer: null,
    card: null,
    time: 0,
    focus: null,

    deck: [],
    pile: []
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.create()
      if (this.parent.multiplication == undefined) {
        this.create()
        this.parent.multiplication = JSON.stringify(this.table)
        this.save()
        console.log('table created')
      } else {
        let table = JSON.parse(this.parent.multiplication)
        this.table = table
        console.log('table loaded')
      }
      this.timer = setInterval(() => {
        this.time++
      }, 1)
    },
    create() {
      let deck = []
      for( let b=1; b<=12; b++) {
        for( let a=1; a<=12; a++) {
          deck.push({
            a: a,
            b: b,
            product: a*b,
            correct: 0,
            attempts: 0,
            time: 0,
            level: 0,
            history: [
              // {
              //   guess: 0,
              //   time: 0,
              //   correct: false
              // }
            ]
          })
        }
      }
      this.deck = deck
    },
    train() {
      // assemble a training deck
      let pile = []
      if (this.focus == '') {
        this.pile = this.deck
      } else {
        for (let i=0; i<this.deck.length; i++) {
          if (this.deck[i].a == this.focus || this.deck[i].b == this.focus) pile.push(this.deck[i])
        }
      }
      // this.training = true
      // this.flash()
    },
    flash() {
      let index = Math.floor(Math.random() * this.table.length)
      if (this.focus && (this.table[index].a != this.focus && this.table[index].b != this.focus)) return this.flash()
      this.card = this.table[index]
      if( this.card.correct > 10 + (10*(this.card.attempts - this.card.correct)) ) return this.flash()
      this.card.index = index
      this.time = 0
    },
    check() {
      if (this.card.guess == this.card.answer) {
        this.table[this.card.index].correct++  
      }
      this.card.guess = ""
      this.table[this.card.index].attempts++
      this.table[this.card.index].speed = this.time
      this.flash()
    },
    stop() {
      this.training = false
      this.parent.multiplication = JSON.stringify(this.table)
      this.$emit('save')
      console.log('Saved!')
    },
    reset() {
      this.create()
      this.parent.multiplication = JSON.stringify(this.table)
      // this.$emit('save')
      console.log('table reset')
    }
  },
  watch: {
    parent() {
      this.init()
    }
  }
}
</script>
<style scoped>
  .box {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    margin: 1px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .box.known {
    border: none;
    background-color: white;
  }
  .header {
    /* color: lightg; */
    font-weight: 600;
    border: none;
  }
  .mini-flash-card {
    width: 30px;
    height: 40px;
    margin-left: -10px;
    margin-bottom: 5px;
    color: grey;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>