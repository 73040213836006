<template>
  <v-app>
    <NavigationDrawer :parent="parent" :globalDrawer="drawer" :menu="menu" @close="drawer=false" @logout="logout" />
    <Header :parent="parent" :menu="menu" @drawer="drawer = true" @logout="logout"></Header>
    <v-main>
      <router-view :parent="parent" @save="saveParent()"/>
    </v-main>
    <Footer :parent="parent"></Footer>
  </v-app>
</template>
<script>
import { getAuth, onAuthStateChanged, getAdditionalUserInfo, signOut } from 'firebase/auth'
import { getFirestore, doc, setDoc, onSnapshot } from 'firebase/firestore'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import NavigationDrawer from './components/NavigationDrawer.vue'
export default {
  name: 'App',
  components: {
    Header, Footer, NavigationDrawer
  },
  data: () => ({
    parent: false,
    parentListener: null,
    drawer: false,
    menu: [
      { id: 0, title: 'Home', icon: 'mdi-view-dashboard', route: 'Landing', authenticated: false },
      { id: 1, title: 'Dashboard', icon: 'mdi-view-dashboard', route: 'Dashboard', authenticated: true },
    ]
  }),
  created() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log('auth delta', user)
        // Fetch user account
        const db = getFirestore()
        const parentRef = doc(db, 'parents', user.uid)
        this.parentListener = onSnapshot(parentRef, doc => {
          if (doc.data()) {
            // ------------ Existing User ------------
            this.parent = doc.data()
            console.log('welcome back', doc.data())
          } else {
            // ------------ New User ------------
            console.log('create new user')
            setDoc(parentRef, {
              uid: user.uid,
              profile: {
                name: user.displayName,
                email: user.email,
                avatar: user.photoURL,
                verified: user.emailVerified,
                phone: {
                  number: user.phoneNumber,
                  verified: false
                },
                registered: Date.now(),
              }
            }).then(() => {
              this.go('Dashboard')
            }).catch(() => {
              console.log('User data could not be saved')
              this.logout()
            })
          }
        })
      }
    })
  },
  methods: {
    saveParent() {
      const auth = getAuth()
      const db = getFirestore()
      const userRef = doc(db, 'parents', auth.currentUser.uid)
      setDoc(userRef, this.parent).then(() => {
        console.log('Parent data saved')
      }).catch(() => {
        console.log('Parent data could not be saved')
      })
    },
    logout() {
      if (this.userListener != null) this.userListener() // Unsubscribe from the user listener
      const auth = getAuth()
      signOut(auth).then(() => { // Sign-out successful.
        this.user = false
        this.go('Login')
      }).catch((error) => { // An error happened.
        console.log('Something went wrong. Could not sign out.')
      });
    },
  }
}
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
  .v-main {
    background: url('./assets/images/japanese.gif');
  }
  .flex-none {
    flex: none;
  }
  .flex-1 {
    flex: 1;
  }
  .v-main__wrap {
    display: flex;
  }
  .japanese-text {
    font-family: 'Noto Sans JP', sans-serif;
  }
  .width-100 {
    width: 100%;
  }
  .height-100 {
    height: 100vh;
  }
</style>
