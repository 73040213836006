<template>
  <div>
     <div class="element">
        <div class="font-weight-bold">Earth</div>
        <ol>
          <li>Responsibility</li>
          <li>Steadfastness</li>
          <li>Reliability</li>
        </ol>
     </div>
     <div class="element">
        <div class="font-weight-bold">Fire</div>
        <div class="text-caption">Representing intensity and transformation</div>
        <ol>
          <li>Courage</li>
          <li>Perseverance</li>
          <li>Enthusiasm</li>
        </ol>
     </div>
     <div class="element">
        <div class="font-weight-bold">Air</div>
        <div class="text-caption">Representing intellect and adaptability</div>
        <ol>
          <li>Curiosity</li>
          <li>Open-mindedness</li>
          <li>Inventiveness</li>
        </ol>
     </div>
     <div class="element">
        <div class="font-weight-bold">Water</div>
        <div class="text-caption">Symbol of emotional depth and fluidity</div>
        <ol>
          <li>Kindness</li>
          <li>Empathy</li>
          <li>Flexibility</li>
        </ol>
     </div>
     <v-divider></v-divider>
     <div class="element">
        <div class="font-weight-bold">Lightning (air + fire)</div>
        <div class="text-caption">Denoting sudden inspiration and energy</div>
        <ol>
          <li>Creativity</li>
          <li>Alertness</li>
          <li>Innovation</li>
        </ol>
     </div>
     <div class="element">
        <div class="font-weight-bold">Ice (air + water)</div>
        <div class="text-caption">Represents resilience and tranquillity</div>
        <ol>
          <li>Calmness</li>
          <li>Resilience</li>
          <li>Patience</li>
        </ol>
     </div>
     <div class="element">
        <div class="font-weight-bold">Metal (fire + earth)</div>
        <div class="text-caption">Symbolizing strength, structure, and discipline</div>
        <ol>
          <li>Discipline</li>
          <li>Determination</li>
          <li>Orderliness</li>
        </ol>
     </div>
     <div class="element">
        <div class="font-weight-bold">Nature (earth + water)</div>
        <div class="text-caption">Representing life, interconnectivity, and growth</div>
        <ol>
          <li>Nurturing</li>
          <li>Balance</li>
          <li>Gratitude</li>
        </ol>
     </div>
  </div>
</template>
<script>
export default {
  name: 'Dashboard',
  props: ['parent'],
  data: () => ({
    
  }),
}
</script>