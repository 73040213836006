<template>
  <v-container>
    <v-card>
      <v-card-title>Register</v-card-title>
      <v-card-text v-if="authenticationType==''">
        <v-text-field 
          v-model="email"
          label="E-mail"
          :disabled="authenticating"
          :validate-on-blur="true"
          outlined
          hide-details>
        </v-text-field>
        <v-btn block class="mt-3" color="primary" @click="authenticationType='email'" :disabled="!email"><v-icon left>mdi-email</v-icon>Continue with Email</v-btn>
        <!-- OR -->
        <div class="d-flex flex-row justify-center my-3 login-divider">
          <div class="px-3 overline login-divider-or">or</div>
        </div>
        <v-btn block outlined color="red" class="darken-1 mt-3" dark @click="googleAuth()"><v-icon left>mdi-google</v-icon>Continue with Google</v-btn>
        <div class="caption mt-3">By processing, you agree to the <a @click="go('Terms')">Terms and Conditions</a> and <a @click="go('Privacy')">Privacy Policy</a></div>
        <div class="caption">Already have an account? <a @click="go('Login')">Log in</a></div>
      </v-card-text>
      <v-card-text v-if="authenticationType=='email'">
        <v-alert type="error" v-if="authFeedback">{{ authFeedback }}</v-alert>
        <v-form ref="registrationForm" v-model="validRegistrationForm">
          <v-text-field 
            v-if="authFeedback"
            v-model="email"
            label="E-mail"
            :rules="emailRules"
            :disabled="authenticating"
            :validate-on-blur="true"
            outlined>
          </v-text-field>
          <div v-else class="overline mb-3">Email: {{ email }}</div>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :disabled="authenticating"
            :rules="passwordRules"
            :type="showPassword ? 'text' : 'password'"
            :validate-on-blur="true"
            label="Password"
            hint="At least 8 characters"
            @click:append="showPassword = !showPassword"
            outlined>
          </v-text-field>
          <v-btn @click="emailRegistration()" class="mt-3" color="primary" block :loading="authenticating" :disabled="!email || !password">Register</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { getAuth, createUserWithEmailAndPassword, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth'
export default {
  name: 'Register',
  data() {
    return {
      email: '',
      password: '',
      authenticating: false,
      authenticationType: '',
      showPassword: false,
      validRegistrationForm: null,
      authFeedback: '',
      passwordRules: [
        value => !!value || 'Password is required.',
        value => value && value.length >= 8 || 'Min 8 characters',
        value => value && value.toLowerCase() !== value || 'Must contain one uppercase character',
        value => value && value.toUpperCase() !== value || 'Must contain one lowercase character',
        value => value && /^[a-zA-Z0-9]+$/.test(value) === false || 'Must contain a complex character (eg. !@#$%^&*?)',
      ],
      emailRules: [
        value => !!value || 'Email is required.',
        value => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Please enter a valid email address'
      ],
    }
  },
  methods: {
    emailRegistration() {
      if (!this.validRegistrationForm) return false
      this.authenticating = true
      this.authFeedback = ''
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password).then( (credentials) => {
        this.go('Dashboard')
      }).catch((error) => {
        switch (error.code) {
          case "auth/missing-email":
            this.authFeedback = 'Please enter an email'; break;
          case "auth/missing-password":
            this.authFeedback = 'Please enter a password'; break;
          case "auth/invalid-email":
           this.authFeedback = 'Please enter a valid email'; break;
          case "auth/user-not-found":
            this.authFeedback = 'Invalid credentials'; break;
          case "auth/email-already-in-use":
            this.authFeedback = 'That account already exists'; break;
          default:
            this.authFeedback = error.code
        }
        this.authenticating = false
      });
    },
    googleAuth() {
      this.overlay = true
      this.authFeedback = ''
      let provider = new GoogleAuthProvider()
      let auth = getAuth()
      provider.addScope('email')
      provider.addScope('profile')
      // provider.addScope('https://www.googleapis.com/auth/user.birthday.read')
      // provider.addScope('https://www.googleapis.com/auth/user.gender.read')
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      signInWithRedirect(auth, provider).then( credentials => {
        this.go('Dashboard')
      }).catch( error => {
        if (error.code == 'auth/popup-closed-by-user') {
          this.authFeedback = 'Google authentication popup closed.'
        } else {
          this.authFeedback = error.message
        }
        this.overlay = false
      })
    }
  }
}
</script>