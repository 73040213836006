import Vue from 'vue'
import VueRouter from 'vue-router'

import Landing from '../views/public/Landing.vue'
import Login from '../views/public/Login.vue'
import Lost from '../views/public/Lost.vue'
import Recover from '../views/public/Recover.vue'
import Register from '../views/public/Register.vue'

import CardMaster from '../views/private/CardMaster.vue'
import Dashboard from '../views/private/Dashboard.vue'
import Math from '../views/private/Math.vue'
import Socrates from '../views/private/Socrates.vue'

import Admin from '../views/admin/Admin.vue'

import { getAuth } from 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  // ---------------------- Public Routes ----------------------
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      scope: 'public'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      scope: 'public',
      state: 'unauthenticated'
    }
  },
  {
    path: '*',
    name: 'Lost', 
    component: Lost,
    meta: {
      scope: 'public'
    }
  },
  {
    path: '/recover',
    name: 'Recover',
    component: Recover,
    meta: {
      scope: 'public',
      state: 'unauthenticated'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      scope: 'public',
      state: 'unauthenticated'
    }
  },
  // ---------------------- Private Routes ----------------------
  {
    path: '/cardmaster',
    name: 'CardMaster',
    component: CardMaster,
    meta: {
      scope: 'private'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      scope: 'private'
    }
  },
  {
    path: '/math',
    name: 'Math',
    component: Math,
    meta: {
      scope: 'private'
    }
  },
  {
    path: '/socrates',
    name: 'Socrates',
    component: Socrates,
    meta: {
      scope: 'private'
    }
  },
  // ---------------------- Admin Routes ----------------------
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      scope: 'admin'
    }
  },
  // ---------------------- System Routes ----------------------
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve( (to,from,next) => {
  let user = getAuth().currentUser
  // ---------------------- Public ----------------------
  if (to.meta.scope == 'public') {
    if (to.meta.state == 'unauthenticated' && user) {
      next({name: 'Dashboard'})
    } else {
      next()
    }
  } else {
    // ---------------------- Not Public ----------------------
    if (to.meta.scope == 'private' && user) {
      next()
    } else if (to.meta.scope == 'admin' && user && user.uid == 'iGXaHx2x3DWZCzJ6svlEmVzVzjE3') {
      next()
    } else {
      next({name: 'Login'})
    }
  }
})

export default router
