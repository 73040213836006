<template>
  <v-footer>
    {{ codename }} v{{ version }}
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    codename: process.env.VUE_APP_CODENAME,
    version: process.env.VUE_APP_VERSION
  })
}
</script>