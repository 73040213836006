var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('v-divider'),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Earth")]),_c('ol',[_c('li',[_vm._v("Responsibility")]),_c('li',[_vm._v("Steadfastness")]),_c('li',[_vm._v("Reliability")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Fire")]),_c('div',{staticClass:"text-caption"},[_vm._v("Representing intensity and transformation")]),_c('ol',[_c('li',[_vm._v("Courage")]),_c('li',[_vm._v("Perseverance")]),_c('li',[_vm._v("Enthusiasm")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Air")]),_c('div',{staticClass:"text-caption"},[_vm._v("Representing intellect and adaptability")]),_c('ol',[_c('li',[_vm._v("Curiosity")]),_c('li',[_vm._v("Open-mindedness")]),_c('li',[_vm._v("Inventiveness")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Water")]),_c('div',{staticClass:"text-caption"},[_vm._v("Symbol of emotional depth and fluidity")]),_c('ol',[_c('li',[_vm._v("Kindness")]),_c('li',[_vm._v("Empathy")]),_c('li',[_vm._v("Flexibility")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Lightning (air + fire)")]),_c('div',{staticClass:"text-caption"},[_vm._v("Denoting sudden inspiration and energy")]),_c('ol',[_c('li',[_vm._v("Creativity")]),_c('li',[_vm._v("Alertness")]),_c('li',[_vm._v("Innovation")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Ice (air + water)")]),_c('div',{staticClass:"text-caption"},[_vm._v("Represents resilience and tranquillity")]),_c('ol',[_c('li',[_vm._v("Calmness")]),_c('li',[_vm._v("Resilience")]),_c('li',[_vm._v("Patience")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Metal (fire + earth)")]),_c('div',{staticClass:"text-caption"},[_vm._v("Symbolizing strength, structure, and discipline")]),_c('ol',[_c('li',[_vm._v("Discipline")]),_c('li',[_vm._v("Determination")]),_c('li',[_vm._v("Orderliness")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Nature (earth + water)")]),_c('div',{staticClass:"text-caption"},[_vm._v("Representing life, interconnectivity, and growth")]),_c('ol',[_c('li',[_vm._v("Nurturing")]),_c('li',[_vm._v("Balance")]),_c('li',[_vm._v("Gratitude")])])])
}]

export { render, staticRenderFns }